import { createContext, Dispatch } from 'react';
import { IProjectHeaderContent } from '../features/Project/_Header/models';

export interface IAppProviderState {
  gamepackKey: string;
  gamepack: IAppProviderProject[] | null;
}

export const AppProviderContext = createContext({
  state: {} as IAppProviderState,
  dispatch: {} as Dispatch<AppProviderReducerAction>,
});

export enum appProviderReducerActionType {
  SET_GAMEPACK_KEY,
  SET_GAMEPACK,
}

export interface AppProviderReducerAction {
  type: appProviderReducerActionType;
  payload?: any;
}

export interface IAppProviderProject extends IProjectHeaderContent {
  versions: any[];
  selectedVersions: number[];
}
