import React, { useReducer } from 'react';
import { AppProviderContext } from './models';
import { initializeReducer, reducer } from './_utils';

const AppProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initializeReducer());

  return (
    <AppProviderContext.Provider value={{ state: state, dispatch: dispatch }}>
      {children}
    </AppProviderContext.Provider>
  );
};

export default AppProvider;
