import React, {
  Dispatch,
  SetStateAction,
  useEffect,
  useRef,
  useState,
} from 'react';
import { Table } from 'react-bootstrap';
import { Link, useOutletContext, useParams } from 'react-router-dom';
import { useProjectService } from '../../../services/ProjectsService';
import { MCHammer } from '../../../components/Loader';
import { notify } from '../../../components/Toast/Toast';
import { ILinkedList, ILLNode, IVersionsCompare } from './models';
import styled from 'styled-components';
import { countConfigDiffs, onSortByIndexArray } from './helpers';
import { PencilSquare } from 'react-bootstrap-icons';
import { ScrollToTopToBottomBtns } from '../../../components/ScrollToTopToBottomBtns/ScrollToTopToBottomBtns';
import FormCheckInput from 'react-bootstrap/FormCheckInput';
import FormCheckLabel from 'react-bootstrap/FormCheckLabel';
import { ProjectHeaderOutlet } from '../_Header/models';
import { ProjectStoreIcon } from '../../../components/ProjectStoreIcon/ProjectStoreIcon';

export const VersionsCompare: React.FC = () => {
  const { loading, error, compareVersions } = useProjectService();
  const { project } = useOutletContext<ProjectHeaderOutlet>();
  const { list } = useParams();

  const [viewHeader, setViewHeader] = useState(false);
  const [groupJsonFields, setGroupJsonFields] = useState(false);
  const [contentLastScrollTop, setContentLastScrollTop] = useState(0);
  const [contentLastScrollLeft, setContentLastScrollLeft] = useState(0);
  const topScroll = useRef<HTMLTableElement | null>(null);
  const contentScroll = useRef<HTMLTableElement | null>(null);

  const [diff, setDiff] = useState<IVersionsCompare | null>(null);
  const [ll, setLL] = useState<ILinkedList[] | null>(null);

  const [over, setOver] = useState('');

  useEffect(() => {
    compareVersions(project.id, list as string).then((data) => {
      let sorted = data.Sum.map((head: ILinkedList) => {
        if (head.Head.Next !== null) {
          head.Head.Next = onSortByIndexArray(head.Head.Next);
        }
        return head;
      });
      sorted = sorted.sort((a: ILinkedList) => {
        return a.Head.Next === null ? -1 : 1;
      });
      setLL(sorted);
      setDiff(data);
    });
    document.addEventListener('scroll', toggleVisible);
    return () => document.removeEventListener('scroll', toggleVisible);
  }, []);

  useEffect(() => {
    error && notify('error', error.toString());
  }, [error]);

  const toggleVisible = () => {
    const scrolled = document.documentElement.scrollTop;
    if (scrolled > window.innerHeight / 3) {
      setViewHeader(true);
    } else {
      setViewHeader(false);
    }
  };

  const onContentScroll = (e: any) => {
    if (e.target.scrollTop !== contentLastScrollTop) {
      setContentLastScrollTop(e.target.scrollTop);
    }
    if (e.target.scrollLeft !== contentLastScrollLeft) {
      if (topScroll.current) {
        topScroll.current.scrollLeft = e.target.scrollLeft;
      }
      setContentLastScrollLeft(e.target.scrollLeft);
    }
  };

  const onTopScroll = (e: any) => {
    if (contentScroll.current) {
      contentScroll.current.scrollLeft = e.target.scrollLeft;
    }
  };

  return (
    <>
      {loading && <MCHammer />}
      {!loading && diff && ll && (
        <>
          <ScrollToTopToBottomBtns />
          {viewHeader && (
            <StyledTop>
              <Table
                borderless
                responsive
                ref={topScroll}
                onScroll={onTopScroll}
              >
                <thead>
                  <tr>
                    <th>Name</th>
                    {HeaderTable(diff)}
                  </tr>
                </thead>
              </Table>
            </StyledTop>
          )}
          <div className="d-flex align-items-center">
            <StyledH3 className="pt-3 pb-2">Compare versions</StyledH3>
            <div className="ms-3">
              <FormCheckInput
                id="newCompareMode"
                className="me-1"
                checked={groupJsonFields}
                onChange={() => setGroupJsonFields((prevState) => !prevState)}
              />
              <FormCheckLabel htmlFor="newCompareMode">
                Group JSON Fields
              </FormCheckLabel>
            </div>
          </div>
          <StyledTable
            as={Table}
            borderless
            ref={contentScroll}
            onScroll={onContentScroll}
          >
            <thead>
              <tr>
                <th>Name</th>
                {HeaderTable(diff)}
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Comment</td>
                {diff.Versions.map((v) => (
                  <td key={`${v.ProjectID}-${v.Version}`}>
                    <span className="text-muted">
                      {diff?.Comments[`${v.ProjectID}-${v.Version}`]}
                    </span>
                  </td>
                ))}
              </tr>
              {AdsGroupsTable(diff, over, setOver)}
              {AdsPlacesTable(diff, over, setOver)}
              {groupJsonFields &&
                GameSettingsTable(diff, ll, setLL, over, setOver)}
              {!groupJsonFields && OldGameSettingsTable(diff, over, setOver)}
            </tbody>
          </StyledTable>
        </>
      )}
    </>
  );
};

const HeaderTable = (diff: IVersionsCompare) => {
  let content: JSX.Element[] = [];
  for (const version of diff.Versions) {
    content.push(
      <th
        key={`${version.ProjectID}-${version.Version}`}
        className="text-break"
      >
        <div className="d-inline-flex align-items-center">
          <ProjectStoreIcon name={version.ProjectName} />
          {version.Version}
        </div>
      </th>
    );
  }
  return content;
};

const AdsGroupsTable = (
  diff: IVersionsCompare,
  over: string,
  setOver: Dispatch<SetStateAction<string>>
) => {
  let content: JSX.Element[] = [];
  for (const key in diff?.AdsGroupsDiff) {
    content.push(
      <tr key={key}>
        <td>
          {key}
          <br />
          <small className="text-primary">Ads Group</small>
        </td>
        {diff?.Versions.map((v) => (
          <td
            key={`${v.ProjectID}-${v.Version}`}
            className="align-baseline"
            onMouseOver={() => setOver(`${key}-${v.ProjectID}-${v.Version}`)}
            onMouseLeave={() => setOver('')}
          >
            {diff?.AdsGroups[`${v.ProjectID}-${v.Version}`][key] ===
            undefined ? (
              <>
                <span className="fw-light fst-italic text-muted">Not Set</span>
                {over === `${key}-${v.ProjectID}-${v.Version}` && (
                  <Link
                    to={`/projects/${v.ProjectID}/versions/${v.VersionID}/ads_places`}
                    target="_blank"
                    className="ms-2"
                  >
                    <PencilSquare className="me-2" height={16} width={16} />
                  </Link>
                )}
              </>
            ) : (
              <>
                <span>
                  {diff?.AdsGroups[`${v.ProjectID}-${v.Version}`][key]}
                </span>
                {over === `${key}-${v.ProjectID}-${v.Version}` && (
                  <Link
                    to={`/projects/${v.ProjectID}/versions/${v.VersionID}/ads_places/0`}
                    target="_blank"
                    className="ms-2"
                  >
                    <PencilSquare className="me-2" height={16} width={16} />
                  </Link>
                )}
              </>
            )}
          </td>
        ))}
      </tr>
    );
  }
  return content;
};

const AdsPlacesTable = (
  diff: IVersionsCompare,
  over: string,
  setOver: Dispatch<SetStateAction<string>>
) => {
  let content: JSX.Element[] = [];

  for (const key in diff?.AdsPlacesDiff) {
    content.push(
      <tr key={key}>
        <td>
          {key}
          <br />
          <small className="text-warning">Ads Place</small>
        </td>
        {diff?.Versions.map((v) => (
          <td
            key={`${v.ProjectID}-${v.Version}`}
            className="align-baseline"
            onMouseOver={() => setOver(`${key}-${v.ProjectID}-${v.Version}`)}
            onMouseLeave={() => setOver('')}
          >
            {diff?.AdsPlaces[`${v.ProjectID}-${v.Version}`][key] ===
            undefined ? (
              <>
                <span className="fw-light fst-italic text-muted">Not Set</span>
                {over === `${key}-${v.ProjectID}-${v.Version}` && (
                  <Link
                    to={`/projects/${v.ProjectID}/versions/${v.VersionID}/ads_places/0`}
                    target="_blank"
                    className="ms-2"
                  >
                    <PencilSquare className="me-2" height={16} width={16} />
                  </Link>
                )}
              </>
            ) : (
              <>
                <span>
                  {diff?.AdsPlaces[`${v.ProjectID}-${v.Version}`][key]}
                </span>
                {over === `${key}-${v.ProjectID}-${v.Version}` && (
                  <Link
                    to={`/projects/${v.ProjectID}/versions/${v.VersionID}/ads_places`}
                    target="_blank"
                    className="ms-2"
                  >
                    <PencilSquare className="me-2" height={16} width={16} />
                  </Link>
                )}
              </>
            )}
          </td>
        ))}
      </tr>
    );
  }
  return content;
};

const OldGameSettingsTable = (
  diff: IVersionsCompare,
  over: string,
  setOver: Dispatch<SetStateAction<string>>
) => {
  let content: JSX.Element[] = [];
  for (const key in diff?.GameSettingsDiff) {
    content.push(
      <tr key={key}>
        <td>
          {key}
          <br />
          <small className="text-info ">Remote Config</small>
        </td>
        {diff?.Versions.map((v, index) => (
          <td
            key={`${v.ProjectID}-${v.Version}`}
            onMouseOver={() =>
              setOver(`${index}-oldgms-${v.ProjectID}-${v.Version}`)
            }
            onMouseLeave={() => setOver('')}
            className="align-baseline"
          >
            {diff?.GameSettings[`${v.ProjectID}-${v.Version}`][key] ===
            undefined ? (
              <>
                <span className="fw-light fst-italic text-muted">Not Set</span>
                {over === `${index}-oldgms-${v.ProjectID}-${v.Version}` && (
                  <Link
                    to={`/projects/${v.ProjectID}/versions/${v.VersionID}/game_settings/0`}
                    target="_blank"
                    className="ms-2"
                  >
                    <PencilSquare className="me-2" height={16} width={16} />
                  </Link>
                )}
              </>
            ) : (
              <>
                <span>
                  {diff?.GameSettings[`${v.ProjectID}-${v.Version}`][key]}
                </span>
                {over === `${index}-oldgms-${v.ProjectID}-${v.Version}` && (
                  <Link
                    to={`/projects/${v.ProjectID}/versions/${
                      v.VersionID
                    }/game_settings/${key.split('.')[0].replace(/\[.*$/, '')}`}
                    target="_blank"
                    className="ms-2"
                  >
                    <PencilSquare className="me-2" height={16} width={16} />
                  </Link>
                )}
              </>
            )}
          </td>
        ))}
      </tr>
    );
  }
  return content;
};
const GameSettingsTable = (
  diff: IVersionsCompare,
  ll: ILinkedList[],
  setLL: Dispatch<SetStateAction<ILinkedList[] | null>>,
  over: string,
  setOver: Dispatch<SetStateAction<string>>
) => {
  let content: JSX.Element[] = [];

  const expanderView = (el: ILinkedList) => {
    if (!el.Head.ShowNext) {
      return (
        <>
          <div role={'button'} onClick={() => onOpenHead(el.Head.Data)}>
            <svg
              fill={'#2aa198'}
              width="1em"
              height="1em"
              style={{
                verticalAlign: 'middle',
                color: 'rgb(42, 161, 152)',
                height: '1em',
                width: '1em',
              }}
              viewBox="0 0 1792 1792"
            >
              <path d="M1344 800v64q0 14-9 23t-23 9h-352v352q0 14-9 23t-23 9h-64q-14 0-23-9t-9-23v-352h-352q-14 0-23-9t-9-23v-64q0-14 9-23t23-9h352v-352q0-14 9-23t23-9h64q14 0 23 9t9 23v352h352q14 0 23 9t9 23zm128 448v-832q0-66-47-113t-113-47h-832q-66 0-113 47t-47 113v832q0 66 47 113t113 47h832q66 0 113-47t47-113zm128-832v832q0 119-84.5 203.5t-203.5 84.5h-832q-119 0-203.5-84.5t-84.5-203.5v-832q0-119 84.5-203.5t203.5-84.5h832q119 0 203.5 84.5t84.5 203.5z"></path>
            </svg>
          </div>
          <div role={'button'} onClick={() => onOpenHead(el.Head.Data)}>
            <>{el.Head.Data}</>
          </div>
        </>
      );
    } else {
      return (
        <>
          <div role={'button'} onClick={() => onOpenHead(el.Head.Data)}>
            <svg
              fill="#586e75"
              width="1em"
              height="1em"
              style={{
                color: 'rgb(88, 110, 117)',
                height: '1em',
                width: '1em',
              }}
              viewBox="0 0 1792 1792"
            >
              <path d="M1344 800v64q0 14-9 23t-23 9h-832q-14 0-23-9t-9-23v-64q0-14 9-23t23-9h832q14 0 23 9t9 23zm128 448v-832q0-66-47-113t-113-47h-832q-66 0-113 47t-47 113v832q0 66 47 113t113 47h832q66 0 113-47t47-113zm128-832v832q0 119-84.5 203.5t-203.5 84.5h-832q-119 0-203.5-84.5t-84.5-203.5v-832q0-119 84.5-203.5t203.5-84.5h832q119 0 203.5 84.5t84.5 203.5z"></path>
            </svg>
          </div>
          <div
            className="ms-1"
            role={'button'}
            onClick={() => onOpenHead(el.Head.Data)}
          >
            <>{el.Head.Data}</>
          </div>
        </>
      );
    }
  };

  const onOpenHead = (head: string) => {
    setLL(
      ll.map((h) => {
        if (h.Head.Data === head) {
          h.Head.ShowNext = !h.Head.ShowNext;
          return h;
        }
        return h;
      })
    );
  };

  const onOpenGroup = (head: string, title: string, index: number) => {
    setLL(
      ll.map((h) => {
        if (h.Head.Data === head) {
          if (h.Head.Next) {
            h.Head.Next = onOpenGroupIndex(h.Head.Next, title, index);
          }
        }
        return h;
      })
    );
  };

  const onOpenGroupIndex = (ll: ILLNode[], title: string, index: number) => {
    return ll.map((node) => {
      if (node.DepthIndex !== index) {
        if (node.Next) {
          node.Next = onOpenGroupIndex(node.Next, title, index);
          return node;
        }
      }
      if (node.DepthIndex === index && node.Data === title) {
        node.ShowNext = !node.ShowNext;
        return node;
      }
      return node;
    });
  };

  ll.forEach((el) => {
    if (el.Head.Next !== null) {
      content.push(
        <tr key={el.Head.Data + el.Head.DepthIndex}>
          {!el.Head.ShowNext && (
            <>
              <td>
                <span className="d-flex align-content-center">
                  {expanderView(el)}
                </span>
              </td>
              <td
                colSpan={diff.Versions.length}
                className="text-center text-muted"
              >
                {countConfigDiffs(el.Head.Data, diff.GameSettingsDiff)}{' '}
                differences
              </td>
            </>
          )}
          {el.Head.ShowNext && (
            <td colSpan={diff.Versions.length + 1} className="pe-0">
              <span className="d-flex align-content-center">
                {expanderView(el)}
              </span>
              {el.Head.ShowNext && el.Head.Next && (
                <ul>
                  {GameSettingNode(
                    el.Head,
                    el.Head.Data,
                    el.Head.Data,
                    diff,
                    onOpenGroup,
                    over,
                    setOver
                  )}
                </ul>
              )}
              <br />
              <small className="text-info">Remote Config</small>
            </td>
          )}
        </tr>
      );
    } else {
      content.push(
        <tr key={el.Head.Data + el.Head.DepthIndex}>
          <td>
            <>{el.Head.Data}</>
            <br />
            <small className="text-info">Remote Config</small>
          </td>
          {diff?.Versions.map((v) => (
            <td
              key={`${v.ProjectID}-${v.Version}`}
              className="align-baseline"
              onMouseOver={() =>
                setOver(
                  `${el.Head.Data + el.Head.DepthIndex}-${v.ProjectID}-${
                    v.Version
                  }`
                )
              }
              onMouseLeave={() => setOver('')}
            >
              {diff?.GameSettings[`${v.ProjectID}-${v.Version}`][
                el.Head.DiffKey
              ] === undefined ? (
                <>
                  <span className="fw-light fst-italic text-muted">
                    Not Set
                  </span>
                  {over ===
                    `${el.Head.Data + el.Head.DepthIndex}-${v.ProjectID}-${
                      v.Version
                    }` && (
                    <Link
                      to={`/projects/${v.ProjectID}/versions/${v.VersionID}/game_settings/0`}
                      target="_blank"
                      className="ms-2"
                    >
                      <PencilSquare className="me-2" height={16} width={16} />
                    </Link>
                  )}
                </>
              ) : (
                <>
                  <span>
                    {
                      diff?.GameSettings[`${v.ProjectID}-${v.Version}`][
                        el.Head.DiffKey
                      ]
                    }
                  </span>
                  {over ===
                    `${el.Head.Data + el.Head.DepthIndex}-${v.ProjectID}-${
                      v.Version
                    }` && (
                    <Link
                      to={`/projects/${v.ProjectID}/versions/${v.VersionID}/game_settings/${el.Head.Data}`}
                      target="_blank"
                      className="ms-2"
                    >
                      <PencilSquare className="me-2" height={16} width={16} />
                    </Link>
                  )}
                </>
              )}
            </td>
          ))}
        </tr>
      );
    }
  });

  return content;
};

const GameSettingNode = (
  el: ILLNode,
  head: string,
  cfgName: string,
  diff: IVersionsCompare,
  onOpenGroup: (head: string, title: string, index: number) => void,
  over: string,
  setOver: Dispatch<SetStateAction<string>>
) => {
  const expanderView = (node: ILLNode) => {
    if (!node.ShowNext) {
      return (
        <div
          role={'button'}
          onClick={() => onOpenGroup(head, node.Data, node.DepthIndex)}
        >
          <svg
            fill={'#2aa198'}
            width="1em"
            height="1em"
            style={{
              verticalAlign: 'middle',
              color: 'rgb(42, 161, 152)',
              height: '1em',
              width: '1em',
            }}
            viewBox="0 0 1792 1792"
          >
            <path d="M1344 800v64q0 14-9 23t-23 9h-352v352q0 14-9 23t-23 9h-64q-14 0-23-9t-9-23v-352h-352q-14 0-23-9t-9-23v-64q0-14 9-23t23-9h352v-352q0-14 9-23t23-9h64q14 0 23 9t9 23v352h352q14 0 23 9t9 23zm128 448v-832q0-66-47-113t-113-47h-832q-66 0-113 47t-47 113v832q0 66 47 113t113 47h832q66 0 113-47t47-113zm128-832v832q0 119-84.5 203.5t-203.5 84.5h-832q-119 0-203.5-84.5t-84.5-203.5v-832q0-119 84.5-203.5t203.5-84.5h832q119 0 203.5 84.5t84.5 203.5z"></path>
          </svg>
        </div>
      );
    } else {
      return (
        <div
          role={'button'}
          onClick={() => onOpenGroup(head, node.Data, node.DepthIndex)}
        >
          <svg
            fill="#586e75"
            width="1em"
            height="1em"
            style={{
              color: 'rgb(88, 110, 117)',
              height: '1em',
              width: '1em',
            }}
            viewBox="0 0 1792 1792"
          >
            <path d="M1344 800v64q0 14-9 23t-23 9h-832q-14 0-23-9t-9-23v-64q0-14 9-23t23-9h832q14 0 23 9t9 23zm128 448v-832q0-66-47-113t-113-47h-832q-66 0-113 47t-47 113v832q0 66 47 113t113 47h832q66 0 113-47t47-113zm128-832v832q0 119-84.5 203.5t-203.5 84.5h-832q-119 0-203.5-84.5t-84.5-203.5v-832q0-119 84.5-203.5t203.5-84.5h832q119 0 203.5 84.5t84.5 203.5z"></path>
          </svg>
        </div>
      );
    }
  };

  const compareView = (
    node: ILLNode,
    over: string,
    setOver: Dispatch<SetStateAction<string>>
  ) => {
    return (
      <StyledRemoteTable as={Table} hover className="m-0 p-0">
        <tbody>
          <tr>
            <td className="ms-4">
              <span>{node.Data}:</span>
            </td>
            {diff?.Versions.map((v) => (
              <td
                key={`${v.ProjectID}-${v.Version}`}
                onMouseOver={() =>
                  setOver(`${node.Data}-${v.ProjectID}-${v.Version}`)
                }
                onMouseLeave={() => setOver('')}
              >
                {diff?.GameSettings[`${v.ProjectID}-${v.Version}`][
                  node.DiffKey
                ] === undefined ? (
                  <>
                    <span className="fw-light fst-italic text-muted">
                      Not Set
                    </span>
                    {over === `${node.Data}-${v.ProjectID}-${v.Version}` && (
                      <Link
                        to={`/projects/${v.ProjectID}/versions/${v.VersionID}/game_settings/0`}
                        target="_blank"
                        className="ms-2"
                      >
                        <PencilSquare className="me-2" height={16} width={16} />
                      </Link>
                    )}
                  </>
                ) : (
                  <>
                    <span>
                      {
                        diff?.GameSettings[`${v.ProjectID}-${v.Version}`][
                          node.DiffKey
                        ]
                      }
                    </span>
                    {over === `${node.Data}-${v.ProjectID}-${v.Version}` && (
                      <Link
                        to={`/projects/${v.ProjectID}/versions/${v.VersionID}/game_settings/${cfgName}`}
                        target="_blank"
                        className="ms-2"
                      >
                        <PencilSquare className="me-2" height={16} width={16} />
                      </Link>
                    )}
                  </>
                )}
              </td>
            ))}
          </tr>
        </tbody>
      </StyledRemoteTable>
    );
  };

  const titleView = (node: ILLNode) => {
    if (node.IndexOfArray >= 0) {
      if (node.ShowNext) {
        return (
          <>
            <span className="indexColor">{node.Data}</span>: [{' '}
            <small className="text-muted fw-normal fst-italic ms-2">
              {node.Next!.length} items
            </small>
          </>
        );
      } else {
        return (
          <>
            <span className="indexColor">{node.Data}</span>: [
            <span className="arrayInsideColor"> ... </span>]
            <small className="text-muted fw-normal fst-italic ms-2">
              {node.Next!.length} items
            </small>
          </>
        );
      }
    } else {
      if (!node.ShowNext) {
        return (
          <>
            {node.Data}: [<span className="arrayInsideColor"> ... </span>]
            <small className="text-muted fw-normal fst-italic ms-2">
              {node.Next!.length} items
            </small>
          </>
        );
      } else {
        return (
          <>
            {node.Data}: [{' '}
            <small className="text-muted fw-normal fst-italic ms-2">
              {node.Next!.length} items
            </small>
          </>
        );
      }
    }
  };

  return (
    el.Next &&
    el.ShowNext &&
    el.Next.map((node) => (
      <div key={node.Data + node.DepthIndex}>
        <span className="d-flex align-content-center">
          {node.Next !== null && (
            <>
              {expanderView(node)}
              <div className="ms-1">
                {node.Next.length > 0 && (
                  <div
                    role={'button'}
                    onClick={() =>
                      onOpenGroup(head, node.Data, node.DepthIndex)
                    }
                  >
                    {titleView(node)}
                  </div>
                )}
                {!node.Next && <>{node.Data}:</>}
              </div>
            </>
          )}
          {node.Next === null && compareView(node, over, setOver)}
        </span>
        <div className="ulBorder">
          <ul>
            {node.Next !== null && (
              <>
                {GameSettingNode(
                  node,
                  head,
                  cfgName,
                  diff,
                  onOpenGroup,
                  over,
                  setOver
                )}
              </>
            )}
          </ul>
        </div>
        {node.Next !== null && node.Next.length >= 1 && node.ShowNext && (
          <div className="ms-1">
            <>]</>
          </div>
        )}
      </div>
    ))
  );
};

const StyledH3 = styled.h3`
  padding: 1.6rem 0;
`;

const StyledTable = styled.table`
  overflow: auto;
  display: block;
  table-layout: auto;

  tr {
    border-bottom: 1px solid #dee2e6;
  }

  th {
    padding: 1rem;
    border-top: 1px solid #dee2e6;
    border-bottom: 2px solid #dee2e6;
    background-color: white;
    opacity: 0.9;
    color: black;
    top: 0;
    vertical-align: middle;

    &:first-child {
      min-width: 30rem;
    }

    &:not(:first-child) {
      min-width: 30rem;
      width: 30rem;
      max-width: 30rem;
      word-break: break-all;
    }
  }

  td {
    padding: 1.2rem;

    &:first-child {
      word-break: break-all;
    }

    &:not(:first-child) {
      min-width: 30rem;
      width: 30rem;
      max-width: 30rem;
      word-break: break-all;
    }
  }

  .ulBorder {
    border-left: 1px solid #dee2e6;
    margin-left: 0.4em;
  }

  .indexColor {
    color: rgb(108, 113, 196);
  }

  .arrayInsideColor {
    color: rgb(203, 75, 22);
  }
`;

const StyledRemoteTable = styled.table`
  .border-row {
    border-left: 1px solid #dee2e6;
  }

  tr {
    border-bottom: 1px solid #dee2e6;
  }

  td {
    padding-left: 1.2rem;
    padding-top: 0.3rem;
    padding-right: 0.3rem;
    padding-bottom: 0.3rem;

    &:not(:first-child) {
      min-width: 30rem;
      width: 30rem;
      word-break: break-all;
    }
  }
`;

const StyledTop = styled.div`
  table {
    overflow: auto;
    display: block;
    table-layout: auto;
  }

  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 5555;
  padding-left: 18px;
  padding-right: 18px;
  background-color: white;

  table {
    th {
      padding: 1rem;
      border-top: 1px solid #dee2e6;
      border-bottom: 2px solid #dee2e6;
      background-color: white;
      color: black;
      top: 0;
      vertical-align: middle;

      &:first-child {
        min-width: 30rem;
      }

      &:not(:first-child) {
        min-width: 30rem;
        width: 30rem;
        max-width: 30rem;
        word-break: break-all;
      }
    }
  }
`;
