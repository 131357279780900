import { createContext, Dispatch } from 'react';

export interface IProjectHeaderState {
  project: IProjectHeaderContent | null;
  oppositeProject: IProjectHeaderContent | null;
  gamepack: IProjectHeaderContent[];
  list: IProjectHeaderContent[];
}

export const ProjectContext = createContext({
  state: {} as IProjectHeaderState,
  dispatch: {} as Dispatch<ReducerAction>,
});

export enum reducerActionType {
  SET_PROJECT,
  SET_OPPOSITE_PROJECT,
  SET_GAMEPACK,
  SET_LIST,
}

export interface ReducerAction {
  type: reducerActionType;
  payload?: any;
}

export interface IProjectHeaderContent {
  id: number;
  name: string;
  appKey: string;
  bundle: string;
  platform: string;
  storeTitle: string;
  company: string;
  china: boolean;
  storeUrl: string;
  chinaCloneOfName: string;
  globalLocalization: boolean;
  experiments: boolean;
  _filter: string;
}

export interface IProjectHeaderFeature {
  eventKey: string;
  label: string;
  datatestid: string;
  to: string;
  active: boolean;
}

export interface ProjectHeaderOutlet {
  project: IProjectHeaderContent;
  oppositeProject: IProjectHeaderContent | null;
}
