import React from 'react';

interface IXiaomiIcon {
  className?: string;
  size?: number;
}
export const IconXiaomi: React.FC<IXiaomiIcon> = ({ className, size }) => {
  return (
    <img
      src="/img/xiaomi_logo.png"
      alt="xiaomi_store_logo"
      className={className}
      height={size}
      width={size}
      // style={{
      //   filter: 'grayscale(100%)',
      // }}
    />
  );
};
