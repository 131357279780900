import React from 'react';

interface IAndroidIcon {
  className?: string;
  size?: number;
}

export const IconAndroid: React.FC<IAndroidIcon> = ({
  className,
  size = 12,
}) => {
  return (
    <img
      src="/img/android.svg"
      alt="android_icon"
      className={className}
      width={size}
      height={size}
    />
  );
};
