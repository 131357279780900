import React from 'react';

interface IAndroidChinaIcon {
  className?: string;
  size?: number;
}

export const IconAndroidChina: React.FC<IAndroidChinaIcon> = ({
  className,
  size = 12,
}) => {
  return (
    <img
      src="/img/android_china.svg"
      alt="androi_china_icon"
      className={className}
      width={size}
      height={size}
    />
  );
};
