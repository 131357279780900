import {
  AppProviderReducerAction,
  appProviderReducerActionType,
  IAppProviderProject,
  IAppProviderState,
} from './models';
import { IProjectHeaderContent } from '../features/Project/_Header/models';

export const initializeReducer = () => {
  return {
    gamepackKey: '',
    gamepack: null,
  } as IAppProviderState;
};

export const reducer = (
  prevState: IAppProviderState,
  action: AppProviderReducerAction
) => {
  switch (action.type) {
    case appProviderReducerActionType.SET_GAMEPACK_KEY:
      return { ...prevState, gamepackKey: action.payload };

    case appProviderReducerActionType.SET_GAMEPACK:
      return { ...prevState, gamepack: action.payload };

    default:
      return prevState;
  }
};

export const handleAppProviderGamepack = (list: IProjectHeaderContent[]) => {
  return list.map((gp) => {
    return {
      id: gp.id,
      name: gp.name,
      appKey: gp.appKey,
      bundle: gp.bundle,
      platform: gp.platform,
      storeTitle: gp.storeTitle,
      company: gp.company,
      china: gp.china,
      storeUrl: gp.storeUrl,
      globalLocalization: gp.globalLocalization,
      experiments: gp.experiments,
      versions: [] as any[],
      selectedVersions: [] as number[],
    } as IAppProviderProject;
  });
};
