import React, { useContext } from 'react';
import { Card } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import {
  PERMISSION_MULTI_COMPANIES,
  SecondaryColor,
} from '../../services/_utils';
import useAuth from '../../hooks/useAuth';
import classNames from 'classnames';
import styled from 'styled-components';
import { IconApple } from '../../components/IconApple/IconApple';
import { IconAndroid } from '../../components/IconAndroid/IconAndroid';
import { IProject } from './models';
import { IconChina } from '../../components/IconChina/IconChina';
import { BrowserChrome, PcDisplay } from 'react-bootstrap-icons';
import { ProjectsStateContext } from './ProjectList';
import { areFirstTwoWordsDiff } from './_utils';

export const ProjectCard: React.FC<IProject> = ({
  id,
  name,
  appKey,
  platform,
  status,
  company,
  china,
  storeTitle,
}) => {
  const { hasPermission } = useAuth();

  const cardClassName = classNames('h-100 shadow-sm ', {
    published: status === 'published',
    softlaunched: status === 'softlaunched',
    hold: status === 'hold',
  });
  const iconClassName = classNames('d-flex mt-2', {
    'justify-content-around': china,
    'justify-content-center': !china,
  });

  return (
    <Styled>
      <Card
        as={Link}
        to={`${id}`}
        data-testid="lnkProjectListGameCard"
        className={cardClassName}
      >
        <GameCard as={Card.Body}>
          <Card.Title className="box">
            <div>
              <div className="appIconWrapper">
                <img
                  className="appIcon"
                  src={`https://storeapi.saygames.io/api/v1/icon?appkey=${appKey}&return_empty_on_error=true&size=small${
                    china ? '&country=cn' : ''
                  }`}
                  alt={`${appKey}_icon`}
                  loading="lazy"
                  height={44}
                  width={44}
                />
              </div>
              <div className={iconClassName}>
                {platform === 'pc' && (
                  <PcDisplay
                    className="ms-2"
                    size={20}
                    color={SecondaryColor}
                  />
                )}
                {platform === 'ios' && <IconApple size={20} />}
                {platform === 'android' && <IconAndroid size={20} />}
                {platform === 'web' && (
                  <BrowserChrome
                    className=""
                    size={18}
                    color={SecondaryColor}
                  />
                )}

                {china && <IconChina size={20} />}
              </div>
            </div>
            <div className="titleSection">
              <div className="d-flex flex-column h-100">
                <div className="text-break">{name}</div>
                <div className="mt-1">
                  {hasPermission(PERMISSION_MULTI_COMPANIES) ? (
                    <span>{company}</span>
                  ) : null}
                </div>
                {areFirstTwoWordsDiff(name, storeTitle) && (
                  <span className="mt-1">{storeTitle}</span>
                )}
              </div>
            </div>
          </Card.Title>
        </GameCard>
      </Card>
    </Styled>
  );
};

const Styled = styled.div`
  .card {
    text-decoration: none;
    color: #212529;
    border-radius: 16px;
    border-color: #e9ecef;

    &:hover {
      text-decoration: none;
      color: #212529;
    }
  }

  .published {
    border-bottom-color: #00c565;
    border-bottom-width: 1.5px;
  }

  .softlaunched {
    border-bottom-color: #ffc107;
    border-bottom-width: 1.5px;
  }

  .hold {
    background-color: rgb(229 231 235);
    opacity: 0.5;
  }
`;

const GameCard = styled.div`
  padding: 1.3rem 1.4rem;

  .box {
    display: flex;
    align-items: flex-start;
    margin: 0;
    padding: 0;
    height: 100%;
    font-size: 0.9rem;
  }

  .titleSection {
    height: 100%;
    padding-left: 0.75rem;
    margin-top: 0.1rem;
  }

  .appIconWrapper {
    position: relative;

    &::after {
      content: '';
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      box-sizing: border-box;
      position: absolute;
      border: 1px solid rgba(0, 0, 0, 0.1);
      border-radius: 10px;
    }

    .appIcon {
      padding: 0;
      border-radius: 10px;
    }
  }

  span {
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
      'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji',
      'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
    font-size: 11px;
    font-weight: 500;
    line-height: 13px;
    letter-spacing: 0;
    text-align: left;
    color: #adb5bd;
  }
`;
