import React from 'react';
import { BrowserChrome } from 'react-bootstrap-icons';

interface IWebIcon {
  className?: string;
  size?: number;
}
export const IconWeb: React.FC<IWebIcon> = ({ className, size }) => {
  return <BrowserChrome size={size} />;
};
