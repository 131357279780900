import React from 'react';

interface ISteamIcon {
  className?: string;
  size?: number;
}

export const IconSteam: React.FC<ISteamIcon> = ({ className, size = 12 }) => {
  return (
    <img
      src="data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 64 64' aria-labelledby='title' aria-describedby='desc' role='img' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Ctitle%3ESteam%3C/title%3E%3Cdesc%3EA solid styled icon from Orion Icon Library.%3C/desc%3E%3Cpath data-name='layer1' d='M31.945 0A32.028 32.028 0 0 0 0 29.433l17.181 7.091a9.021 9.021 0 0 1 5.106-1.574q.256 0 .505.014l7.642-11.057v-.155a12.088 12.088 0 1 1 12.088 12.07h-.275l-10.9 7.76c.007.144.012.288.012.429a9.075 9.075 0 0 1-17.97 1.786L1.1 40.72A32.047 32.047 0 1 0 31.945 0z' fill='%23202020'%3E%3C/path%3E%3Cpath data-name='layer1' d='M20.088 48.555l-3.937-1.625a6.8 6.8 0 1 0 3.725-9.293l4.069 1.679a5.005 5.005 0 1 1-3.857 9.238zm30.489-24.803a8.055 8.055 0 1 0-8.054 8.04 8.059 8.059 0 0 0 8.054-8.04zm-14.093-.013a6.051 6.051 0 1 1 6.05 6.039 6.045 6.045 0 0 1-6.049-6.04z' fill='%23202020'%3E%3C/path%3E%3C/svg%3E"
      alt="Steam"
      className={className}
      height={size}
      width={size}
    />
  );
};
