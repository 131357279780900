import React, { useContext, useEffect, useReducer } from 'react';
import { useProjectService } from '../../../services/ProjectsService';
import { MCHammer } from '../../../components/Loader';
import { notify } from '../../../components/Toast/Toast';
import {
  IProjectHeaderContent,
  ProjectContext,
  ProjectHeaderOutlet,
  reducerActionType,
} from './models';
import { initializeReducer, reducer, sortGamePack } from './_utils';
import { Outlet, useLocation, useParams } from 'react-router-dom';
import classNames from 'classnames';
import { GameSwitcher } from './GameSwitcher';
import { FeatureBar } from './FeatureBar';
import {
  AppProviderContext,
  appProviderReducerActionType,
} from '../../../AppProvider/models';
import { handleAppProviderGamepack } from '../../../AppProvider/_utils';

const HeaderProject: React.FC = () => {
  const { projectId } = useParams();
  const { pathname } = useLocation();
  const [state, dispatch] = useReducer(reducer, initializeReducer());
  const { state: appProviderState, dispatch: appProviderDispatch } =
    useContext(AppProviderContext);

  const { loading, error, getProjectHeader } = useProjectService();

  const onLoad = () => {
    dispatch({
      type: reducerActionType.SET_PROJECT,
      payload: null,
    });

    getProjectHeader(projectId!).then((resp) => {
      if (appProviderState.gamepackKey !== resp.gamepackKey) {
        appProviderDispatch({
          type: appProviderReducerActionType.SET_GAMEPACK_KEY,
          payload: resp.gamepackKey,
        });
        appProviderDispatch({
          type: appProviderReducerActionType.SET_GAMEPACK,
          payload: handleAppProviderGamepack([...resp.gamepack, resp.project]),
        });
      }
      dispatch({
        type: reducerActionType.SET_PROJECT,
        payload: resp.project,
      });
      dispatch({
        type: reducerActionType.SET_OPPOSITE_PROJECT,
        payload: resp.oppositeProject,
      });
      dispatch({
        type: reducerActionType.SET_GAMEPACK,
        payload: sortGamePack(resp.gamepack, resp.oppositeProject),
      });
      dispatch({
        type: reducerActionType.SET_LIST,
        payload: resp.list.map((p: IProjectHeaderContent) => {
          p._filter =
            p.id.toString() +
            ' ' +
            p.name.toLowerCase() +
            ' ' +
            p.appKey.toLowerCase() +
            ' ' +
            p.bundle.toLowerCase() +
            ' ' +
            p.company.toLowerCase() +
            ' ' +
            p.platform.toLowerCase() +
            ' ' +
            p.storeTitle.toLowerCase();

          return p;
        }),
      });
    });
  };

  useEffect(() => {
    onLoad();
  }, [projectId]);

  useEffect(() => {
    error && notify('error', error);
  }, [error]);

  if (loading || !state.project) return <MCHammer />;

  const mainClassname = classNames({
    'container-xxl':
      !pathname.includes('/compare-versions') &&
      !pathname.includes('/device_logs'),
    'container-fluid':
      pathname.includes('/compare-versions') ||
      pathname.includes('/device_logs'),
  });

  return (
    <ProjectContext.Provider value={{ state: state, dispatch: dispatch }}>
      <div className={mainClassname}>
        <GameSwitcher />
        <FeatureBar />
        <Outlet
          context={
            {
              project: state.project,
              oppositeProject: state.oppositeProject,
            } as ProjectHeaderOutlet
          }
        />
      </div>
    </ProjectContext.Provider>
  );
};

export default HeaderProject;
