import React from 'react';

interface IHuaweiIcon {
  className?: string;
  size?: number;
}
export const IconHuawei: React.FC<IHuaweiIcon> = ({ className, size }) => {
  return (
    <img
      src="/img/huawei_store_icon.png"
      alt="huawei_store_logo"
      className={className}
      height={size}
      width={size}
      // style={{
      //   filter: 'grayscale(100%)',
      // }}
    />
  );
};
