import React from 'react';

interface IAmazonIcon {
  className?: string;
  size?: number;
}
export const IconAmazon: React.FC<IAmazonIcon> = ({ className, size }) => {
  return (
    <img
      src="/img/amazon_store_icon.png"
      alt="amazon_store_logo"
      className={className}
      height={size}
      width={size}
      // style={{
      //   filter: 'grayscale(100%)',
      // }}
    />
  );
};
