import {
  IProjectHeaderContent,
  IProjectHeaderState,
  ReducerAction,
  reducerActionType,
} from './models';

export const initializeReducer = () => {
  return {
    project: null,
    oppositeProject: null,
    gamepack: [] as IProjectHeaderContent[],
    list: [] as IProjectHeaderContent[],
  } as IProjectHeaderState;
};

export const reducer = (
  prevState: IProjectHeaderState,
  action: ReducerAction
) => {
  switch (action.type) {
    case reducerActionType.SET_PROJECT:
      return { ...prevState, project: action.payload };

    case reducerActionType.SET_OPPOSITE_PROJECT:
      return { ...prevState, oppositeProject: action.payload };

    case reducerActionType.SET_GAMEPACK:
      return { ...prevState, gamepack: action.payload };

    case reducerActionType.SET_LIST:
      return { ...prevState, list: action.payload };

    default:
      return prevState;
  }
};

export const sortGamePack = (
  list: IProjectHeaderContent[],
  oppositeOsProject: IProjectHeaderContent
) => {
  const index = list.findIndex((v) => v.id === oppositeOsProject.id);
  if (index === -1) {
    return list;
  }

  const [item] = list.splice(index, 1);
  list.unshift(item);

  return list;
};
