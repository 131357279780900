import React from 'react';

interface IAppleChinaIcon {
  className?: string;
  size?: number;
}

export const IconAppleChina: React.FC<IAppleChinaIcon> = ({
  className,
  size = 12,
}) => {
  return (
    <img
      src="/img/apple_china.svg"
      alt="apple_china_icon"
      className={className}
      width={size}
      height={size}
    />
  );
};
