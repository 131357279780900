import { IconWeb } from '../IconWeb/IconWeb';
import { IconHuawei } from '../IconHuawei/IconHuawei';
import { IconSteam } from '../IconSteam/IconSteam';
import { IconAmazon } from '../IconAmazon/IconAmazon';
import { IconXiaomi } from '../IconXiaomi/IconXiaomi';
import { IconAppleChina } from '../IconAppleChina/IconAppleChina';
import { IconApple } from '../IconApple/IconApple';
import { IconAndroidChina } from '../IconAndroidChina/IconAndroidChina';
import { IconAndroid } from '../IconAndroid/IconAndroid';
import React from 'react';

export const ProjectStoreIcon: React.FC<{ name: string }> = ({ name }) => {
  const iOS = '(iOS)';
  const Android = '(Android)';
  const Web = '(Web)';
  const China = 'China';
  const Huawei = 'Huawei';
  const Xiaomi = 'Xiaomi';
  const Amazon = 'Amazon';
  const Steam = 'Steam';

  if (name.indexOf(Web) !== -1) {
    return <IconWeb size={24} />;
  }

  if (name.indexOf(Huawei) !== -1) {
    return <IconHuawei size={24} />;
  }

  if (name.indexOf(Steam) !== -1) {
    return <IconSteam size={23} />;
  }

  if (name.indexOf(Amazon) !== -1) {
    return <IconAmazon size={26} />;
  }

  if (name.indexOf(Xiaomi) !== -1) {
    return <IconXiaomi size={24} />;
  }

  if (name.indexOf(Steam) !== -1) {
    return <IconSteam size={26} />;
  }

  if (name.indexOf(iOS) !== -1) {
    if (name.indexOf(China) !== -1) {
      return <IconAppleChina size={28} />;
    }
    return <IconApple size={28} />;
  }

  if (name.indexOf(Android) !== -1) {
    if (name.indexOf(China) !== -1) {
      return <IconAndroidChina size={28} />;
    }
    return <IconAndroid size={28} />;
  }

  return null;
};
